import { takeLatest, call, put } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { promoCodeActions } from "@/component/GenericBundles/PromoCode/redux/PromoCodeActions";
import { promoCodeApi } from "@/component/GenericBundles/PromoCode/redux/PromoCodeApi";
import { trackClick } from "@/utils/segmentUtils";
import { laPageName } from "@/constant/eventConstants";
import { RootState } from "redux/storeSchema";
import { select } from "redux-saga/effects";

function* fetchPromoCodeValidation(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(
      promoCodeApi.fetchPromoCodeValidation,
      action.payload,
    );
    if (res.success) {
      const payload = {
        data: res.data,
        offerPrice: action.payload.offerPrice,
      };
      yield put(promoCodeActions.fetchPromoCodeValidationSuccess(payload));
      const applicationData = yield select(
        (state: RootState) => state.IeltsDemo.applicationData,
      );
      trackClick(laPageName.LA_App_Fee_Payment, {
        contentName: "Apply_Coupon_LA",
        couponCode: payload.data,
        screenType: applicationData?.course.university,
        programName: applicationData?.course.name,
      });
    } else {
      // const { INVALID_PROMO_CODE } = eventName;
      yield put(
        promoCodeActions.fetchPromoCodeValidationFail(
          res.message || "Invalid code. Try the code LEAPIELTS to get 10% off",
        ),
      );
    }
  } catch (e: any) {
    yield put(
      promoCodeActions.fetchPromoCodeValidationFail(
        "Something went wrong. Please try after sometime",
      ),
    );
  }
}

function* promoCodeSaga() {
  yield takeLatest("FETCH_PROMO_CODE_VALIDATION", fetchPromoCodeValidation);
}

export default promoCodeSaga;
