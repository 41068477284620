import { call, put, takeLatest } from "redux-saga/effects";

import { AnyAction } from "redux";
import { confirmIeltsEliteDemoSlot } from "@/component/Calendar/redux/ieltsDemoApi";
import { isFunction } from "@/utils";
import {
  setHasBookedSlot,
  setError,
  bookIeltsSlot,
} from "@/component/Calendar/redux/IeltsDemoSlice";
import { trackIeltsEvent } from "@/utils/segmentUtils";
import { FormdId } from "@/component/LaForm/formJson";

function* bookIeltsSlotSaga(Action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      confirmIeltsEliteDemoSlot,
      Action.payload.payload,
      Action.payload.isReschedule,
    );
    if (res.success) {
      yield call(trackIeltsEvent, "LA VO Marketing Lead", {
        formId: FormdId.LA_QE_Form_1,
      });
      if (isFunction(Action.payload.postBookingCallBack)) {
        Action.payload.postBookingCallBack();
      } else {
        yield put(setHasBookedSlot(res.data));
      }
    } else {
      yield put(setError(true));
      yield put(setHasBookedSlot(false));
    }
  } catch (e) {
    yield put(setError(true));
    yield put(setHasBookedSlot(false));
  }
}

function* ieltsDemoSaga() {
  yield takeLatest(bookIeltsSlot, bookIeltsSlotSaga);
}

export default ieltsDemoSaga;
