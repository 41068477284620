import { ValidationPayload } from "@/component/GenericBundles/PromoCode/redux/PromoCodeReducer";
import api from "@/services/api";

const fetchPromoCodeValidation = async (payload: ValidationPayload) => {
  try {
    const res = await api.post(
      `/api/leap-advantage/dashboardOpenRoute?path=promocode/apply/${payload.promoCode}`,
      {
        bundleId: payload.bundleId,
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const promoCodeApi = {
  fetchPromoCodeValidation,
};
