import { FunctionComponent } from "react";
import { WithLogin } from "@leapfinance/auth-kit";
import { loginDetails } from "@/component/Common/Login/constant/loginConstant";

const Login: FunctionComponent = () => {
  return (
    <>
      <WithLogin
        {...loginDetails}
        trueCallerDetails={{
          partnerKey: process.env.NEXT_PUBLIC_PARTNER_KEY as string,
          partnerName: process.env.NEXT_PUBLIC_PARTNER_NAME as string,
        }}
      />
    </>
  );
};
export default Login;
