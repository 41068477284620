import createSagaMiddleware, { Task } from "redux-saga";
import { configureStore, Store } from "@reduxjs/toolkit";
import rootSaga from "redux/rootSaga";
import { createWrapper, MakeStore } from "next-redux-wrapper";

import { dashboardRouterBaseApiWithTags } from "redux/services/base/dashboardRouter";
import { dashboardApiWithTags } from "redux/services/base/dashboardApi";
import { rootReducer } from "redux/rootReducer";
import { rtkQueryErrorLogger } from "redux/middleware/rtkErrorHandler";
export interface SagaStore extends Store {
  sagaTask?: Task;
}

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      sagaMiddleware,
      dashboardRouterBaseApiWithTags.middleware,
      dashboardApiWithTags.middleware,
      rtkQueryErrorLogger,
    ]),
});

export const makeStore: MakeStore<any> = () => {
  // 1: Create the middleware
  // 2: Add an extra parameter for applying middleware:

  // 3: Run your sagas on server
  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  // 4: now return the store:
  return store;
};

// export an assembled wrapper
export const wrapper = createWrapper<Store>(makeStore, { debug: false });
