import { AuthToken } from "@leapfinance/auth-kit";

export const getAuthSerialaisableObject = (token: string | undefined) => {
  if (token) {
    const auth = new AuthToken(token);
    return {
      authorizationString: auth.authorizationString,
      decodedToken: auth.decodedToken,
      token: auth.token,
      isValid: auth.isValid,
      isExpired: auth.isExpired,
    };
  }
};
