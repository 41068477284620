import { IMAGE } from "@/constant/imageConstant";
import { NAV_ROUTES } from "@/constant/navRoutesConstant";
import { CountryDto } from "@leapfinance/auth-kit";

export const loginDetails = {
  appName: process.env.NEXT_PUBLIC_AUTH_APP_NAME || "",
  apiPath: NAV_ROUTES.AUTH_PATH || "",
  logo: IMAGE.LOGIN_CARD_IMAGE,
};

export const MenaEnableCountry = [
  CountryDto.UAE,
  CountryDto.BAHRAIN,
  CountryDto.KUWAIT,
  CountryDto.OMAN,
  CountryDto.QATAR,
  CountryDto.SAUDI,
];

export const MiddleEastloginObject = {
  title: "Log into your IELTS dashboard",
  canChangeCountryCode: true,
  canClose: false,
  whiteListedCountries: MenaEnableCountry,
};
export const IndialoginObject = {
  title: "Verify your Phone Number",
  canChangeCountryCode: true,
  canClose: false,
  whiteListedCountries: [CountryDto.INDIA],
};
