import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { NAV_ROUTES } from "@/constant/navRoutesConstant";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action?.payload?.status == 403 || action?.payload?.status == 401) {
        window.open(NAV_ROUTES.LOGOUT, "_self");
      }
      console.warn("We got a rejected action!");
    }
    return next(action);
  };
