const emailRegex = "^undefined$|^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$";
const tenDigitMobileRegex = "^(\\+91[\\-\\s]?)?[0]?(91)?[1-9]\\d{9}$";
const tenDigitMobileRegexV2 = "^(\\+91[\\-\\s]?)?[0]?(91)?[5-9]\\d{9}$";

const nameRegex = "^[A-Za-z\\s]+$";
const numbersAndAlphabetsRegex = "^[a-zA-Z0-9]+$";
export {
  nameRegex,
  emailRegex,
  tenDigitMobileRegex,
  numbersAndAlphabetsRegex,
  tenDigitMobileRegexV2,
};
