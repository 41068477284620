import { RequestMethods } from "@/utils/BulkApiTypes";
import { UtmDataType } from "@/utils/utmUtils";
import {
  BaseResponse,
  LaRefferalDto,
  UserInfoInterface,
} from "redux/services/Apitypes";
import { dashboardRouterBaseApiWithTags } from "redux/services/base/dashboardRouter";
const getUtmDataQueryString = (utmData: UtmDataType) => {
  const queryParam = Object.entries(utmData)
    .filter((item) => item[1])
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return `${queryParam}`;
};
export const userInfoApi = dashboardRouterBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUserData: builder.query<BaseResponse<UserInfoInterface>, void>({
      query: () => `student/basic`,
    }),
    postQeFormData: builder.mutation<BaseResponse<null>, any>({
      query: (payload) => ({
        url: "leap-advantage/pre-user-details",
        method: RequestMethods.POST,
        body: payload,
      }),
    }),
    referUser: builder.mutation<BaseResponse<null>, LaRefferalDto>({
      query: (payload) => ({
        url: `leap-advantage/refer&${getUtmDataQueryString({
          utmCampaign: payload.utmCampaign,
          utmTerm: payload.utmTerm,
          utmSource: payload.utmSource,
        })}`,
        method: RequestMethods.POST,
        body: payload,
      }),
    }),
    referUserIntent: builder.mutation<
      BaseResponse<null>,
      {
        platform: string;
        utmTerm?: string;
        utmSource?: string;
        utmCampaign?: string;
      }
    >({
      query: (payload) => ({
        url: `/leap-advantage/referIntent&userPlatform=${
          payload.platform
        }&${getUtmDataQueryString({
          utmCampaign: payload.utmCampaign,
          utmTerm: payload.utmTerm,
          utmSource: payload.utmSource,
        })}`,
        method: RequestMethods.POST,
      }),
    }),
    getStudentEligibility: builder.query<BaseResponse<boolean>, void>({
      query: () => "/leap-advantage/slots/eligibility",
    }),
    getStudentEligibilityWithoutEvent: builder.query<
      BaseResponse<boolean>,
      void
    >({
      query: () => "/leap-advantage/slots/eligibility",
    }),
  }),
});
export const {
  useLazyGetUserDataQuery,
  useGetUserDataQuery,
  usePostQeFormDataMutation,
  useLazyGetStudentEligibilityQuery,
  useReferUserMutation,
  useReferUserIntentMutation,
  useGetStudentEligibilityWithoutEventQuery,
} = userInfoApi;
