import axios, { AxiosRequestConfig } from "axios";

// Add a request interceptor
const api = axios.create();
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      typeof window !== "undefined"
    ) {
      window.open("/api/leap-advantage/dashboard_logout", "_self");
    } else return Promise.reject(error);
  },
);

export type ApiRequestConfig = AxiosRequestConfig;

export default api;
