import { CircularProgress, Modal } from "@mui/material";

import React, { Suspense } from "react";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import { RootState } from "redux/storeSchema";
import { ModalDataType, selectModal } from "@/component/Modals/modalSlice";

const NoAccessModal = dynamic(
  () => import(`@/component/Modals/NoAccessModal`),
  {
    ssr: false,
  },
);

export enum MODAL_TYPES {
  NO_ACCESS = `NO_ACCESS`,
}

const MODALS: Record<MODAL_TYPES, any> = {
  [MODAL_TYPES.NO_ACCESS]: NoAccessModal,
};

const GlobalModal = () => {
  const modalsData = useSelector((state: RootState) => selectModal(state));

  const renderComponent = (modal: ModalDataType, index: number) => {
    if (modal.modalType === null) return null;
    const ModalComponent = MODALS[modal.modalType];
    return (
      <Modal
        key={index}
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              <CircularProgress />
              Loading modal...
            </div>
          }
        >
          <ModalComponent {...modal.modalProps} />
        </Suspense>
      </Modal>
    );
  };

  return (
    <>
      {modalsData?.map((modal, index) =>
        modal.modalType ? renderComponent(modal, index) : null,
      )}
    </>
  );
};

export default GlobalModal;
