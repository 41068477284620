import format from "date-fns/format";
import isValid from "date-fns/isValid";

export const cloneDeep = (data: any) => JSON.parse(JSON.stringify(data));

export const isFunction = (data: any) => typeof data === "function";
export const debounce = (callback: () => void, delay: number): (() => void) => {
  let timer: NodeJS.Timeout | null = null;
  return function (...args) {
    //@ts-ignore
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
};

export const getObjectKeys = Object.keys as <T extends object>(
  obj: T,
) => Array<keyof T>;

export const formatDfns = (...rest: Parameters<typeof format>) => {
  if (isValid(rest[0])) {
    return format(...rest);
  }
  return "";
};

export const getUserOsName = (reqUserAgent?: string): string => {
  try {
    if (reqUserAgent || typeof window != "undefined") {
      const userAgent = (
        reqUserAgent ||
        window.navigator.userAgent ||
        navigator.vendor ||
        (window as any).opera ||
        ""
      ).toLowerCase();
      if (/android/i.test(userAgent)) {
        return "android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return "ios";
      }
      if (/mac|macintosh|applewebkit/i) {
        return "macintosh";
      }
    }
  } catch (e) {
    console.log("err", e);
  }
  return "window";
};

export const getUserAgent = (): {
  userAgent: string | null;
  completeUserAgent: string | null;
} => {
  try {
    return {
      userAgent: getUserOsName(window.navigator.userAgent),
      completeUserAgent: window.navigator.userAgent,
    };
  } catch (error) {
    return {
      userAgent: null,
      completeUserAgent: null,
    };
  }
};
