import api, { ApiRequestConfig } from "@/services/api";
import { getPlatformV2 } from "@/utils/segmentUtils";
import { SlotTypes } from "redux/services/Apitypes";

export const confirmIeltsEliteDemoSlot = async (
  payload: any,
  isReschedule?: boolean,
) => {
  const { batchId } = payload;
  try {
    const config: ApiRequestConfig = {
      method: "POST",
      url: `/api/leap-advantage/dashboardRoute?path=counselling/register/${batchId}&slotType=${SlotTypes.LA_COUNSELLING}&reschedule=${isReschedule}`,
      headers: {
        "X-IELTS-PLATFORM": getPlatformV2(),
        "X-API-VERSION": "2",
      },
      data: payload,
    };

    const res = await api(config);
    if (res.data.success) {
      return res.data;
    } else {
      return res.data.message;
    }
  } catch (err: any) {
    return { data: null, message: err?.message };
  }
};
