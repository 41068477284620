import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NoAccessModalProps } from "@/component/Modals/NoAccessModal";
import { MODAL_TYPES } from "@/component/Modals/GlobalModal";
import { RootState } from "redux/storeSchema";

type NoAccessModal = {
  modalType: MODAL_TYPES.NO_ACCESS | null;
  modalProps: NoAccessModalProps | null;
};

export type ModalDataType = NoAccessModal;

export type ModalType = ModalDataType[];

const initialState: ModalType = [];

export const modalsSlice = createSlice({
  name: `modals`,
  initialState,
  reducers: {
    updateModal: (state, action: PayloadAction<ModalDataType>) => {
      const newModal = [...state];
      const findModalIndex = state.findIndex(
        (eachModal) => eachModal.modalType === action.payload.modalType,
      );
      if (findModalIndex >= 0) {
        newModal.splice(findModalIndex, 1, action.payload);
      } else {
        newModal.push(action.payload);
      }
      return newModal;
    },
    removeModal: (state, action: PayloadAction<MODAL_TYPES | undefined>) => {
      if (!action.payload || typeof action.payload !== `string`) {
        return [];
      }
      const newModal = state.filter(
        (eachModal) => eachModal.modalType !== action.payload,
      );
      return newModal;
    },
  },
});
export const selectModal = (state: RootState) => {
  return state.modals || [];
};
export const { updateModal, removeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
