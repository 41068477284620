export const laPageName = {
  LA_Landing_Page: "LA_Landing_Page",
  LA_Form_S2: "LA_Form_S2",
  LA_Form_S3: "LA_Form_S3",
  VO_BOOKING_CALENDAR: "VO Booking Calendar",
  QualifiedSuccessScreen: "Qualified Success Screen",
  LADisqualifiedScreen: " LA Disqualified Screen",
  Counsellorsnotavailable: "Counsellors not available",
  _404Pagenotfound: "404 Page not found",
  Login_Sheet: "Login_Sheet",
  LA_App_Fee_Payment: "LA_App_Fee_Payment",
  LA_App_Fee_Success: "LA_App_Fee_Success",
  REGISTRATION_PAGE: "Registration Page",
  LA_REFERRAL_PAGE: "LA Referral Page",
  LA_REFERRAL_THANK_YOU_PAGE: "LA referral thank you page",
};
