import { BaseResponse } from "redux/services/Apitypes";

export const transformResponseToGiveData = ({
  data,
  success,
  message,
}: BaseResponse<any>): any => {
  if (success) {
    return data;
  }
  return { success, message, data: null };
};
