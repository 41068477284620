import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const dashboardRouterBaseApi = createApi({
  reducerPath: `dashboardRouterBaseApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `/api/leap-advantage/dashboardRoute?path=`,
  }),
  endpoints: () => ({}),
});

export const dashboardRouterBaseApiWithTags =
  dashboardRouterBaseApi.enhanceEndpoints({
    addTagTypes: ["dashboardData", "subscriptionStatus", "videoCourse"],
  });
