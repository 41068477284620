import { AnyAction } from "redux";
import { HYDRATE } from "next-redux-wrapper";
import { AuthProps } from "@/component/Common/Login/WithAuth";

const initialState: AuthProps = {
  auth: undefined,
};

// create your reducer
const reducer = (state: AuthProps = initialState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        auth: action.payload.auth?.auth,
      };
    case "SAVE_AUTH":
      return {
        ...state,
        auth: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
