import { getObjectKeys } from "@/utils";
import {
  CookieReqContext,
  CookieResContext,
  getCookie,
  saveUtmCookie,
} from "@/utils/cookieUtils";
import { NextPageContext } from "next";

export type UtmDataType = {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  campaignType?: string;
  adId?: string;
  trackingId?: string;
  referer?: string;
  gclid?: string;
  adName?: string;
  fbclid?: string;
  campaign_id?: string;
  _fbc?: string;
  _fbp?: string;
};

export function getUtmData(ctx: CookieReqContext): UtmDataType | undefined {
  let utmSource,
    utmCampaign,
    utmTerm,
    campaignType,
    adId,
    trackingId,
    utmMedium,
    campaign_id,
    fbclid,
    _fbc,
    _fbp,
    gclid;

  if (getCookie(ctx, "utmSource")) {
    utmSource = getCookie(ctx, "utmSource");
  }
  if (getCookie(ctx, "utmCampaign")) {
    utmCampaign = getCookie(ctx, "utmCampaign");
  }
  if (getCookie(ctx, "utmMedium")) {
    utmMedium = getCookie(ctx, "utmMedium");
  }
  utmTerm = getCookie(ctx, "utmTerm");
  if (getCookie(ctx, "campaignType")) {
    campaignType = getCookie(ctx, "campaignType");
  }
  if (getCookie(ctx, "adId")) {
    adId = getCookie(ctx, "adId");
  }
  if (getCookie(ctx, "trackingId")) {
    trackingId = getCookie(ctx, "trackingId");
  }
  if (getCookie(ctx, "fbclid")) {
    fbclid = getCookie(ctx, "fbclid");
  }
  if (getCookie(ctx, "campaign_id")) {
    campaign_id = getCookie(ctx, "campaign_id");
  }
  if (getCookie(ctx, "gclid")) {
    gclid = getCookie(ctx, "gclid");
  }
  if (getCookie(ctx, "_fbc")) {
    _fbc = getCookie(ctx, "_fbc");
  }
  if (getCookie(ctx, "_fbp")) {
    _fbp = getCookie(ctx, "_fbp");
  }

  return {
    utmSource,
    utmCampaign,
    utmTerm,
    campaignType,
    adId,
    trackingId,
    utmMedium,
    campaign_id,
    fbclid,
    gclid,
    _fbc,
    _fbp,
  } as UtmDataType;
}

export function saveUtmData(ctx: CookieResContext, utmData: UtmDataType) {
  getObjectKeys(utmData).forEach((item) => {
    if (utmData[item]) {
      utmData[item] = encodeURIComponentIfNotEncoded(utmData[item] as string);
    }
  });
  if (utmData.utmSource) {
    saveUtmCookie(ctx, "utmSource", utmData.utmSource);
  }
  if (utmData.utmCampaign) {
    saveUtmCookie(ctx, "utmCampaign", utmData.utmCampaign);
  }
  if (utmData.utmMedium) {
    saveUtmCookie(ctx, "utmMedium", utmData.utmMedium);
  }
  if (utmData.utmTerm) {
    saveUtmCookie(ctx, "utmTerm", utmData.utmTerm);
  }
  if (utmData.campaignType) {
    saveUtmCookie(ctx, "campaignType", utmData.campaignType);
  }
  if (utmData.adId) {
    saveUtmCookie(ctx, "adId", utmData.adId);
  }
  if (utmData.trackingId) {
    saveUtmCookie(ctx, "trackingId", utmData.trackingId);
  }
  if (utmData.fbclid) {
    saveUtmCookie(ctx, "fbclid", utmData.fbclid);
  }
  if (utmData.campaign_id) {
    saveUtmCookie(ctx, "campaign_id", utmData.campaign_id);
  }
  if (utmData.gclid) {
    saveUtmCookie(ctx, "gclid", utmData.gclid);
  }
  if (utmData._fbc) {
    saveUtmCookie(ctx, "_fbc", utmData._fbc);
  }
  if (utmData._fbp) {
    saveUtmCookie(ctx, "_fbp", utmData._fbp);
  }
}

export const captureAndSaveUtm = (context: NextPageContext, query: any) => {
  const utmSource = query && query.utm_source;
  const utmCampaign = query && query.utm_campaign;
  const utmTerm = query && query.utm_term;
  const utmMedium = query && query.utm_medium;
  const campaignType = query && query.campaign_type;
  const adId = query && query.ad_id;
  const trackingId = query && query.tracking_id;
  const gclid = query && query.gclid;
  const adName = query && query.ad_name;
  const fbclid = query && query.fbclid;
  const campaign_id = query && query.campaign_id;
  const _fbc = query && query._fbc;
  const _fbp = query && query._fbp;

  saveUtmData(context, {
    gclid: gclid as string,
    adName: adName as string,
    utmSource: utmSource as string,
    utmCampaign: utmCampaign as string,
    utmMedium: utmMedium as string,
    utmTerm: utmTerm as string,
    adId: adId as string,
    campaignType: campaignType as string,
    trackingId: trackingId as string,
    fbclid: fbclid as string,
    campaign_id: campaign_id as string,
    _fbc: _fbc as string,
    _fbp: _fbp as string,
  });
};
export const getQueryParamStringFromObject = (object: any) => {
  let valuesString: any = Object.keys(object).map((key) =>
    object[key]?.length > 0 ? `${key}=${object[key]}` : ``,
  ) as string[];

  valuesString = valuesString?.filter((n: any) => n)?.join("&");
  valuesString = valuesString.length > 0 ? `&${valuesString}` : ``;
  return valuesString;
};

const isEncodedUriComponent = (str: any) => {
  try {
    // Decode and then re-encode the component
    return encodeURIComponent(decodeURIComponent(str)) === str;
  } catch (e) {
    // If decoding fails, it's not a valid encoded URI component
    return false;
  }
};
export const encodeURIComponentIfNotEncoded = (str: string): string => {
  if (isEncodedUriComponent(str)) {
    return str;
  } else {
    return encodeURIComponent(str);
  }
};
