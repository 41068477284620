import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const dashboardApi = createApi({
  reducerPath: `dashboardApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/leap-advantage/` }),
  endpoints: () => ({}),
});

export const dashboardApiWithTags = dashboardApi.enhanceEndpoints({
  addTagTypes: [],
});
