export interface BulkApiRequest {
  path: string;
  identifier: string;
  method: RequestMethods;
  payload?: any;
  xApiVersion?: string;
}

export enum RequestMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}
export interface BulkApiResponse {
  identifier: string;
  data: any;
  error: string | null;
}
