import { RequestMethods } from "@/utils/BulkApiTypes";
import { transformResponseToGiveData } from "@/utils/rtkUtils";
import {
  BaseResponse,
  DemoDetails,
  LaApplicationResponseDto,
  SlotTypes,
  SpotCounselingLiveCalendarResponseDto,
} from "redux/services/Apitypes";
import { dashboardRouterBaseApiWithTags } from "redux/services/base/dashboardRouter";
export const laCounsellingApi = dashboardRouterBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCounsellingSlots: builder.query<
      SpotCounselingLiveCalendarResponseDto,
      void
    >({
      query: () => ({
        url: `counselling/slots/${SlotTypes.LA_COUNSELLING}/live/30`,
        method: RequestMethods.GET,
        headers: { "X-API-VERSION": "5" },
      }),
      transformResponse: (
        response: BaseResponse<SpotCounselingLiveCalendarResponseDto>,
      ): SpotCounselingLiveCalendarResponseDto => {
        return transformResponseToGiveData(response);
      },
    }),
    getBookedCounsellingSlots: builder.query<DemoDetails[], void>({
      query: () => ({
        url: `counselling/slots/${SlotTypes.LA_COUNSELLING}/booked`,
        method: RequestMethods.GET,
        headers: { "X-API-VERSION": "2" },
      }),
      transformResponse: (
        response: BaseResponse<DemoDetails[]>,
      ): DemoDetails[] => {
        return transformResponseToGiveData(response);
      },
    }),
    getApplicationById: builder.query<
      BaseResponse<LaApplicationResponseDto | null>,
      { applicationId: string }
    >({
      query: (payload) => `leap-advantage/application/${payload.applicationId}`,
    }),
  }),
});
export const {
  useLazyGetCounsellingSlotsQuery,
  useLazyGetBookedCounsellingSlotsQuery,
  useGetApplicationByIdQuery,
} = laCounsellingApi;
