import { ValidationPayload } from "@/component/GenericBundles/PromoCode/redux/PromoCodeReducer";

const fetchPromoCodeValidation = (payload: ValidationPayload) => ({
  type: "FETCH_PROMO_CODE_VALIDATION",
  payload,
});

const fetchPromoCodeValidationSuccess = (payload: any) => ({
  type: "FETCH_PROMO_CODE_VALIDATION_SUCCESS",
  payload,
});

const fetchPromoCodeValidationFail = (message: string) => ({
  type: "FETCH_PROMO_CODE_VALIDATION_FAIL",
  payload: message,
});

const updatePromoCodeApplied = (payload: boolean) => ({
  type: "UPDATE_PROMO_CODE_APPLIED",
  payload,
});

const removePromoCode = () => ({
  type: "REMOVE_PROMO_CODE",
});

const togglePromoCodeForm = (payload: boolean) => ({
  type: "TOGGLE_PROMO_CODE_FORM",
  payload,
});

export const promoCodeActions = {
  fetchPromoCodeValidation,
  fetchPromoCodeValidationSuccess,
  fetchPromoCodeValidationFail,
  updatePromoCodeApplied,
  removePromoCode,
  togglePromoCodeForm,
};
